import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFromProxyActions } from 'features/BookingFormProxy';
import {
  useGetBookingQuery,
  useSetStatusMutation,
  useUpdateBookingFromFormHandler,
} from 'features/api/bookings-api';
import { FormBooking } from 'features/BookingFormProxy/types';
import { invalidateHallSlots } from 'features/api/hallschema-api';
import {
  useCreateGuestMutation,
  useLazyFetchClientQuery,
  useLazyFetchGuestDetailQuery,
  useUpdateGuestMutation,
} from 'features/api/guest-api';
import { useBooleanState } from 'hooks/useBooleanState';
import { useLocation } from 'react-router';
import type { ErrorResponse } from 'types/commons';

const useEditBooking = (bookingId: number) => {
  const [hasManageralError, setHasManageralError] = useState<boolean>(false);
  const { data: booking } = useGetBookingQuery(bookingId);
  const { updateBookingHandler } = useUpdateBookingFromFormHandler();
  const [setStatus] = useSetStatusMutation();
  const { setEditMode } = useFromProxyActions();
  const [createGuest] = useCreateGuestMutation();
  const [getUser] = useLazyFetchGuestDetailQuery();
  const [overbookingData, setOverbookingData]
    = useState<FormBooking | undefined>();
  const dispatch = useDispatch();
  const [isUpdating, start, stop] = useBooleanState();
  const [updateGuest] = useUpdateGuestMutation();
  const [getClient] = useLazyFetchClientQuery();
  const closeModal = () => setHasManageralError(false);
  const { pathname } = useLocation();
  const isDashboard = /^\/dashboard(\/.*)?$/.test(pathname);

  const update = useCallback(
    async (data) => {
      start();
      let { client } = data;
      try {
        const { client_id, phone, email } = data;

        if (client) {
          const {
            editClient = false,
            name,
            surname = '',
            middle_name = '',
          } = client;
          if (!client.client_id) {
            const {
              data: { client_id: newClientId },
            } = await createGuest({
              middle_name,
              name,
              phone,
              surname,
              email,
            }).unwrap();
            client = await getUser(newClientId).unwrap();
          }
          if (editClient && client_id) {
            getClient(client_id)
              .then(async (response) => {
                const newUser = {
                  ...response.data,
                  surname,
                  name,
                  middle_name,
                  type_authorization: 'AUTH',
                };
                return newUser;
              })
              .then(async (newUser) => {
                //@ts-ignore
                await updateGuest(newUser);
              });
          }
        }
        await updateBookingHandler({
          ...data,
          contact: data.contact,
          client:
            Object.keys({
              ...client,
              client_id: client_id ?? client?.client_id,
            }).length
              ? client
              : undefined,
        }).unwrap();
        dispatch(invalidateHallSlots());
      } catch (e) {
        const errorData = (e as ErrorResponse['error'])?.data
        errorData?.errorCode === 10100 && setOverbookingData(data);
        errorData?.errorCode === 10400 && setHasManageralError(true);
        throw e;
      } finally {
        stop();
      }
    },
    [updateBookingHandler, setOverbookingData, dispatch]
  );

  const clearData = useCallback(
    () => setOverbookingData(undefined),
    [setOverbookingData]
  );

  const updateWithOverbooking = useCallback(async () => {
    if (!overbookingData) return;
    start();
    try {
      await updateBookingHandler({ ...overbookingData, force: true }).unwrap();
      dispatch(invalidateHallSlots());
      clearData();
    } finally {
      stop();
    }
  }, [overbookingData, setStatus, updateBookingHandler, dispatch]);

  useEffect(
    () => () => {
      setEditMode(false);
    },
    [setEditMode]
  );

  return {
    booking,
    update,
    overbookingData,
    clearData,
    updateWithOverbooking,
    hasManageralError,
    closeModal,
    isUpdating,
    isDashboard,
  };
};

export default useEditBooking;
