import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { groupBy, uniqBy } from 'lodash';
import cn from 'classnames';
import { config } from 'config';

import { Card, Collapse } from 'ui-kit';

import { Booking } from 'types/booking';
import { useAllStatuses } from 'features/api/dictionaries-api';
import { BookingsList } from 'components/BookingsList';
import { tableBookingsTableSelector } from 'features/TableBooking/selectors';
import { usePlacesTable } from 'features/api/tables-api';
import { useTableBookingListActions } from 'features/TableBooking/slice';
import { Input } from 'ui-kit';
import { useCurrentRestBookings } from 'features/api/bookings-api';
import { RestaurantBookingStats } from 'components/RestaurantBookingStats';

import { TableActions } from 'components/hall-scheme/redux/TableBookingList/TableActions';
import styles from './SortedBookingsList.module.scss';
import { useFromProxyActions } from 'features/BookingFormProxy';
import { useIntl } from 'react-intl';
import { ETranslations } from '../../types/translates';
import { useSlots } from '../../features/api/hallschema-api';
import { useTimelineActions } from '../../features/Timeline';
import { getBookingStartTime } from '../../utils';
import dayjs from 'dayjs';
import { HideWhen } from 'components/HideWhen';
import { appContextSelectors } from 'features/AppContex';
import type { SelectCheckboxOption } from 'components/booking/types';
import { filterBookingsListData } from 'components/booking/utils';

interface SortedBookingsListProps {
  onHide?: () => void;
  className?: string;
  openedByTable?: boolean;
  showAllNodes?: boolean;
  canDrag?: boolean;
  searchQuery?: string;
  includedStatuses?: string[];
  isHallScheme?: true;
  extraStatusFilter?: SelectCheckboxOption[] | null;
}

export function SortedBookingsList({
  onHide,
  className,
  openedByTable,
  showAllNodes = true,
  canDrag = true,
  searchQuery,
  includedStatuses,
  isHallScheme,
  extraStatusFilter,
}: SortedBookingsListProps) {
  const intl = useIntl();
  const { setOnlyBooking: setFormBooking, setEditMode } = useFromProxyActions();
  const { data: statuses } = useAllStatuses();
  const [search, setSearch] = useState<string | undefined>();
  const table = useSelector(tableBookingsTableSelector);
  const { table: tableData, isLoading: isTableLoading } = usePlacesTable(table);
  const isManagerableTableSelected
    = localStorage.getItem('isManagerableTableSelected') === 'true';
  const selectedPlaces = useSelector(appContextSelectors.selectedPlaces);

  const valideStatuses = useMemo(() => {
    if (includedStatuses) {
      return statuses.filter((status) =>
        includedStatuses.includes(status.system_name));
    } else {
      return statuses;
    }
  }, [includedStatuses, statuses]);

  const { data } = useCurrentRestBookings(
    (showAllNodes ? search : searchQuery) || undefined,
    Boolean(!config.hasStatusFiltersInHallScheme && tableData?.table_id),
    includedStatuses && valideStatuses,
    isHallScheme
  );
  const { bookings, statistics, } = filterBookingsListData(
    data,
    extraStatusFilter
  )
  const { data: tableSlotsData } = useSlots();
  const { setTime, resetTimeShift } = useTimelineActions();
  const { reset: resetTable } = useTableBookingListActions();

  const bookingsTable = useMemo(() => {
    const bookingsData = tableSlotsData
      ?.find((el) => el.table.table_id === table)
      ?.slots.map((el) => el.booking)
      .sort((a, b) => {
        const dateA = dayjs(`${a.bookingDate} ${a.bookingTime}`).valueOf();
        const dateB = dayjs(`${b.bookingDate} ${b.bookingTime}`).valueOf();
        return dateA - dateB;
      });
    return bookingsData || [];
  }, [table, tableSlotsData]);

  const bookingsByStatus = useMemo(() => {
    if (!bookings || isTableLoading) return {};
    const uniqBookings = uniqBy(bookings || [], (b) => b.bookingId);
    const filteredBookings = tableData
      ? uniqBookings.filter((b) =>
          b.places.some((p) => p.id === tableData.table_id))
      : uniqBookings;
    return {
      ...groupBy(filteredBookings, (b) => b.status?.system_name),
    };
  }, [bookings, tableData, isTableLoading]);

  const manageralTablesStatus = useMemo(() => {
    const manageralTables = tableSlotsData?.filter((eachTable) => {
      return eachTable?.slots.some(
        (slot) => slot.booking?.seatType === 'MANAGEMENT'
      );
    });

    const filterManageralbookings = manageralTables
      ?.map((eachTable) =>
        eachTable.slots
          .filter((slot) => slot.booking?.seatType === 'MANAGEMENT')
          .map((slot) => slot.booking))
      .flat();

    const filteredBookings = tableData
      ? filterManageralbookings?.filter((booking) =>
          booking.places.some((p) => p.id === tableData?.table_id))
      : filterManageralbookings;

    //@ts-ignore
    return uniqBy(filteredBookings, (b) => b.notes.batch_id);
  }, [tableData?.table_id, tableSlotsData, isTableLoading, table]);

  const handleClose = useCallback(() => {
    onHide?.();
    resetTable();
    resetTimeShift();
  }, [onHide, resetTable]);

  const handleEdit = useCallback((booking: Booking) => {
    setFormBooking(booking);
    setEditMode(true);
    setTime(getBookingStartTime(booking));
  }, []);

  const onSelectBook = useCallback((book: Booking) => {
    setTime(getBookingStartTime(book));
    setFormBooking(book);
  }, []);

  const WithOutStatusFilters = useMemo(() => {
    return (
      <BookingsList
        canDrag={canDrag}
        bookings={bookingsTable}
        className={styles.bookingsList}
        withActions
        onEdit={handleEdit}
        onClick={onSelectBook}
        compact
        allowMove
        showPhone
        showStatus
      />
    );
  }, [bookingsTable, handleEdit, setFormBooking]);

  const WithStatusFilters = useMemo(() => {
    return valideStatuses
      .map((status) => {
        const statusBookings = bookingsByStatus[status.system_name]?.filter(
          (booking) => {
            return booking?.places?.some((place) => {
              return selectedPlaces?.includes(place?.placeId);
            });
          }
        );

        if (!statusBookings?.length) return null;
        return (
          <Collapse
            headerClassName={styles.statusCollapse}
            initialOpen
            header={
              <span>
                {status.name}&nbsp;
                <span className={styles.bookingsCount}>
                  {statusBookings.length}
                </span>
              </span>
            }
            key={status.id}
          >
            <div>
              <BookingsList
                canDrag={canDrag}
                bookings={statusBookings}
                className={styles.bookingsList}
                withActions
                onEdit={handleEdit}
                onClick={setFormBooking}
                compact
                allowMove
                showPhone
                showStatus
              />
            </div>
          </Collapse>
        );
      })
      .filter((el) => el);
  }, [
    valideStatuses,
    handleEdit,
    setFormBooking,
    bookingsByStatus,
    selectedPlaces.length,
  ]);

  const WithManageralTablesStatus = useMemo(() => {
    const sortedManageralTables = manageralTablesStatus?.filter((booking) => {
      return booking?.places?.some((place) =>
        selectedPlaces?.includes(place?.placeId));
    });
    if (sortedManageralTables?.length) {
      return (
        <Collapse
          headerClassName={styles.statusCollapse}
          initialOpen
          header={
            <span>
              {intl.formatMessage({ id: ETranslations.MANAGERAL_TABLES })}&nbsp;
              <span className={styles.bookingsCount}>
                {manageralTablesStatus.length}
              </span>
            </span>
          }
        >
          <BookingsList
            canDrag={canDrag}
            bookings={sortedManageralTables}
            className={styles.bookingsList}
            withActions
            onEdit={handleEdit}
            onClick={setFormBooking}
            compact
            allowMove
            showPhone
            showStatus
          />
        </Collapse>
      );
    } else return null;
  }, [manageralTablesStatus, selectedPlaces.length]);

  return (
    <Card
      onClose={tableData && !openedByTable ? resetTable : handleClose}
      className={cn(className, styles.bookingsCard)}
    >
      <HideWhen condition={!showAllNodes}>
        <Card.Header
          title={
            tableData?.number ? (
              intl.formatMessage(
                { id: ETranslations.TABLE_BOOKINGS },
                { number: tableData.number }
              )
            ) : (
              <RestaurantBookingStats
                stats={statistics}
                title={intl.formatMessage({
                  id: ETranslations.PLURAL_BOOKINGS_NOM,
                })}
              />
            )
          }
        />
      </HideWhen>
      <Card.Content noPadding>
        <HideWhen condition={!showAllNodes}>
          <div className={styles.searchContainer}>
            <Input.Search onSearch={setSearch} />
          </div>
        </HideWhen>
        <div className={styles.list}>
          {config.hasStatusFiltersInHallScheme ? (
            <>
              {WithStatusFilters}
              {isManagerableTableSelected && WithManageralTablesStatus}
            </>
          ) : tableData?.number ? (
            WithOutStatusFilters
          ) : (
            <>
              {WithStatusFilters}
              {!isTableLoading
                && isManagerableTableSelected
                && WithManageralTablesStatus}
            </>
          )}
        </div>
        {tableData && (
          <TableActions table={tableData} className={styles.actions} />
        )}
      </Card.Content>
    </Card>
  );
}
