import { Source, SourceServer } from '../../models/booking.model';
import { Client } from '../../models/client.model';
import { IResponse } from '../../models/common';
import { useMemo } from 'react';
import { ELocales, Response } from '../../types/commons';
import { config } from 'config';
import { api, relativePathApi } from './api';
import { ETranslations } from '../../types/translates';
import { getUserLocale } from '../../utils';
import { Notification } from 'services/notification';
import type { BaseStatus, EditStatusRequest, Status } from 'types/status';

export const dictionariesApi = api
  .enhanceEndpoints({ addTagTypes: ['Dicts'] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchAllSources: build.query<Source[], any>({
        query: () => ({
          url: 'v2/sources',
        }),
        providesTags: ['Dicts'],
        transformResponse: (response: IResponse<SourceServer[]>) =>
          response.data.map((it) => ({
            ...it,
            label: it.source_name,
            value: it.id,
          })),
      }),
      fetchAllStatus: build.query<Status[], void>({
        query: () => ({
          url: 'v2/status',
        }),
        providesTags: ['Dicts', 'Statuses'],
        transformResponse: (response: Response<Status[]>) => response.data,
        onQueryStarted(args, { queryFulfilled }) {
          queryFulfilled.catch((e) => []);
        },
      }),
      editStatus: build.mutation<Response<Status>, EditStatusRequest>({
        query: (status) => ({
          url: `v2/status/${status.status_id}`,
          method: 'POST',
          body: status,
        }),
        // Если в ответе мы получили обновленный статус (проверяем по id), то не инвалидируем кэш, а апдейтим вручную
        invalidatesTags: (
          result,
          err,
          status
        ) => /* result?.data.id === status.status_id
            ? ['Bookings']
            : */ ['Statuses', 'Bookings'],
        onQueryStarted(status, { queryFulfilled, dispatch }) {
          queryFulfilled
            .then(({ data }) => {
              // FIXME: убрать/восстановить по результатам тестов с инвалидацией статусов после редактирования на стенде MAISON
              /* data?.data?.id
                && dispatch(
                  dictionariesApi.util.updateQueryData(
                    'fetchAllStatus',
                    undefined,
                    (draft) => {
                      const statusIndex = draft.findIndex(
                        (s) => s.id === data.data.id
                      );
                      //Если индекс найден, то апдейтим кэш запроса fetchAllStatus
                      ~statusIndex && (draft[statusIndex] = data.data);
                    }
                  )
                ); */
            })
            .catch((e) => {
              if (e.error.data.errorCode === 10000) {
                return Notification.error({
                  title: e.error.data.errorMessage,
                });
              }

              if (e.status === 'ERROR') {
                return Notification.error({
                  title: ETranslations.ERROR_UNABLE_TO_EDIT_STATUS,
                  message: e.error?.message,
                });
              }
              return Notification.error({
                title: ETranslations.ERROR_UNABLE_TO_EDIT_STATUS,
                message: e.error?.message,
              });
            });
        },
      }),
    }),
  });

export const localResourcesApi = relativePathApi.injectEndpoints({
  endpoints: (build) => ({
    fetchTranslates: build.query<Record<ETranslations, string>, string>({
      query: (locale) => ({
        url: `/lang/${locale}.json`,
      }),
    }),
  }),
});

export const {
  useFetchAllSourcesQuery,
  useFetchAllStatusQuery,
  useEditStatusMutation,
} = dictionariesApi;

export const { useFetchTranslatesQuery } = localResourcesApi;

const EMPTY: Status[] = [];

export function useAllStatuses() {
  const { data, ...rest } = useFetchAllStatusQuery();
  return { ...rest, data: data || EMPTY };
}

export function useBaseStatuses() {
  const { data, ...rest } = useFetchAllStatusQuery();
  const baseStatuses = (
    data ? data.filter((status) => !status.is_extra) : EMPTY
  ) as BaseStatus[];
  return { ...rest, data: baseStatuses };
}

export function useActiveStatuses() {
  const { data: allStatuses, ...rest } = useFetchAllStatusQuery();
  const active = useMemo(
    () => allStatuses?.filter((s) => !s.is_terminal) ?? EMPTY,
    [allStatuses]
  );
  return { ...rest, data: active };
}

export function useCompleteStatuses() {
  const { data: allStatuses, ...rest } = useFetchAllStatusQuery();
  const complete = useMemo(
    () => allStatuses?.filter((s) => s.is_terminal) ?? EMPTY,
    [allStatuses]
  );
  return { ...rest, data: complete };
}

export function useTranslates(userLocale?: ELocales): {
  translates: Record<ETranslations, string>;
  isSuccess: boolean;
} {
  const locale = (userLocale || getUserLocale()).split('_')[0];
  const { data: translates = {} as Record<ETranslations, string>, isSuccess }
    = useFetchTranslatesQuery(locale);
  return { translates, isSuccess };
}

export const getActiveStatuses = (statuses: Status[] | undefined) =>
  statuses?.filter((status) => !status.is_terminal) || [];

export const getCompletedStatuses = (statuses: Status[] | undefined) =>
  statuses?.filter((status) => status.is_terminal) || [];
