import { IResponse } from '../../models/common';
import { SwapTarget } from '../../models/booking.model';
import { useSelector } from 'react-redux';
import { appContextSelectors } from 'features/AppContex';
import moment from 'moment';
import { middlePollingInterval } from './utils';
import { Booking } from 'types/booking';
import { Notification } from 'services/notification';
import { bookingApi } from './bookings-api';
import { api } from './api';
import { useEffect, useMemo } from 'react';
import { SeatType } from 'types/place';
import { TimelineShift } from '../../types/timeline';
import dayjs from 'dayjs';
import { useTimelineActions } from '../Timeline';
import { TNullable, Response, ErrorResponse } from '../../types/commons';
import { isNull, isUndefined } from 'lodash';
import { IHall, IPlace, ISector } from '@technolab/hse';

export interface TableMeta {
  id: number;
  capacity: number;
  number: `${number}`;
  type: `${number}`;
}

export interface TTable extends IPlace {
  hostesInfo: TableMeta;
  figure_id: number;
  seat?: `${number}`;
}

export interface Sector extends ISector {
  places?: TTable[];
}

export interface THallScheme extends IHall {
  sectors: Array<Sector>;
  externalSource: boolean;
  hse: boolean;
  is_external_source: boolean;
  is_hse: boolean;
}
import { useIsTabVisible } from 'hooks/useIsTabVisible';
import { ETranslations } from 'types/translates';

export type HallScheduleQResponse = {
  minTime: string;
  maxTime: string;
  workStartHour: number;
  workDurationInHour: number;
  bookingInterval: string;
  workDurationInMinutes: number;
  bookingIntervalInMinutes: number;
};

export type SwapRequest = {
  force?: boolean;
  origin: SwapTarget;
  destination: SwapTarget;
};

export type MoveRequest = {
  force?: boolean;
  origin: SwapTarget;
  destination: {
    booking_id?: number;
    tables_id: number[];
  };
};

export type SlotEvent = {
  /** @deprecated лучше использовать время */
  startShift: number;
  /** @deprecated лучше использовать время */
  endShift: number;
  bookingStartTime: string;
  booking: Booking;
  slotStatus: string;
  isLocked?: boolean;
  depositStatus?: boolean;
  hasComment?: boolean;
  isVIP?: boolean;
};

export type TableSchema = {
  width: number;
  height: number;
  x: number;
  y: number;
  shape: 'square' | 'round' | 'circle';
};

export type Table = {
  table_id: number;
  number: number | string;
  type: number;
  schema: TableSchema;
  placeId: number;
};

export type HallSlotsQResponse = {
  table: Table;
  slots: SlotEvent[];
};

export interface PlacementFilter {
  date: string;
  restaurant_id: number;
  places_filter: number[];
  status_filter: number[];
  seat_type: SeatType[];
  start_time_filter?: string;
  end_time_filter?: string;
  use_visit_time?: boolean;
}

export type PlacementStats = {
  [key: string]: {
    bookings: number;
    guests: number;
  };
} & { time: string };

export type HallSchemeShiftsPayload = {
  restaurant_id: number;
  place_id: number;
  date: string;
};

export type HallSchemeShiftsResponse = {
  timestamp: Date;
  data: TimelineShift[];
};

export const SLOT_TAGS = [{ type: 'Slots', id: 'hallSlots' } as const];

export const hallSchemaApi = api
  .enhanceEndpoints({
    addTagTypes: [
      'Slots',
      'TableOptions',
      'table-endpoint',
      'Restaurants',
      'Schema',
      'HallSchedule',
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchHallSchedule: build.query<
        HallScheduleQResponse,
        { placeId: number; weekDay: number }
      >({
        query: ({ placeId, weekDay }) => ({
          url: `/hall-schema/schedule/${placeId}`,
          params: {
            weekDay,
          },
        }),
        providesTags: (_res, err, args) => [
          { type: 'HallSchedule', id: `${args.placeId}-${args.weekDay}` },
          'Shifts',
        ],
        transformResponse: (response: IResponse<HallScheduleQResponse>) =>
          response.data,
      }),
      fetchHallShiftsSchedule: build.query<
        TimelineShift[],
        HallSchemeShiftsPayload
      >({
        query: (params) => ({
          url: '/v2/hall-schema/schedule',
          params,
        }),
        providesTags: ['Timeline', 'Shifts'],
        keepUnusedDataFor: 60,
        transformResponse: (response: HallSchemeShiftsResponse) =>
          response.data,
      }),
      fetchSlots: build.query<
        HallSlotsQResponse[],
        { placeId: number; date: string; time: string }
      >({
        query: ({ placeId, date, time }) => ({
          url: `/v2/hall-schema/slots/${placeId}`,
          params: {
            date,
            time,
          },
        }),
        transformResponse: (response: IResponse<HallSlotsQResponse[]>) =>
          response.data,
        providesTags: () => [...SLOT_TAGS, 'Bookings'],
      }),
      getBookingPlacement: build.query<PlacementStats[], PlacementFilter>({
        query: (filter) => ({
          url: '/v2/hall/booking/placement',
          method: 'POST',
          body: filter,
        }),
        keepUnusedDataFor: 0,
        transformResponse: (response: IResponse<PlacementStats[]>) =>
          response.data,
        async onQueryStarted(id, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (err) {
            const errorData = (err as ErrorResponse)?.error?.data;
            if (errorData?.errorCode) {
              Notification.error({
                title: errorData?.errorMessage,
              });
            }
            throw err;
          }
        },
      }),
      swap: build.mutation<void, SwapRequest>({
        query: ({ force, ...body }: SwapRequest) => ({
          url: '/v2/booking/swap',
          method: 'POST',
          body,
          params: {
            force,
          },
        }),
        invalidatesTags: [...SLOT_TAGS, 'TableOptions'],
        async onQueryStarted(id, { queryFulfilled, dispatch }) {
          try {
            await queryFulfilled;
            dispatch(bookingApi.util.invalidateTags(['Bookings']));
          } catch (e) {
            const errorData = (e as ErrorResponse)?.error?.data;
            errorData?.errorCode !== 10100
              && Notification.error({
                title:
                  errorData?.errorCode === 10000
                    ? errorData?.errorMessage
                    : 'Failed to swap reservations',
              });
            throw e;
          }
        },
      }),
      move: build.mutation<void, MoveRequest>({
        query: ({ force, ...body }: MoveRequest) => {
          delete body.destination.booking_id;
          return {
            url: '/v2/booking/transfer',
            method: 'POST',
            body,
            params: {
              force,
            },
          };
        },
        invalidatesTags: [...SLOT_TAGS, 'TableOptions'],
        async onQueryStarted(id, { queryFulfilled, dispatch }) {
          try {
            await queryFulfilled;
            dispatch(bookingApi.util.invalidateTags(['Bookings']));
          } catch (e) {
            const errorData = (e as ErrorResponse)?.error?.data;
            errorData?.errorCode !== 10100
              && Notification.error({
                title:
                  errorData?.errorCode === 10000
                    ? errorData?.errorMessage
                    : "Couldn't reschedule the reservation",
              });
            throw e;
          }
        },
      }),
      fetchHallScheme: build.query<THallScheme, { placeId: number }>({
        query: ({ placeId }) => ({
          url: `/v2/tables/${placeId}/schema`,
        }),
        transformResponse: (response: Response<THallScheme>) => response.data,
        keepUnusedDataFor: 0,
        providesTags: ['Schema'],
      }),
      updateHallScheme: build.mutation<
        THallScheme,
        { placeId: number; scheme: THallScheme }
      >({
        query: ({ placeId, scheme }) => ({
          method: 'POST',
          url: `/v2/tables/${placeId}/schema`,
          body: scheme,
        }),
        transformResponse: (response: Response<THallScheme>) => response.data,
        invalidatesTags: ['Restaurants', 'Schema', 'PlacesTables'],
      }),
      fetchTableCheck: build.query<Array<Booking>, string>({
        query: (ids) => ({
          method: 'GET',
          url: `v2/tables/bookings/check`,
          params: { ids },
        }),
        transformResponse: (response: Response<Array<Booking>>) =>
          response.data,
        keepUnusedDataFor: 0,
        async onQueryStarted(_, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (e) {
            Notification.error({
              title: ETranslations.ERROR_FETCHING_BOOKINGS,
            });
            throw e;
          }
        },
      }),
    }),
  });

export const {
  useSwapMutation,
  useMoveMutation,
  useGetBookingPlacementQuery,
  useUpdateHallSchemeMutation,
  useFetchTableCheckQuery,
  useLazyFetchTableCheckQuery,
} = hallSchemaApi;
const {
  useFetchHallScheduleQuery,
  useFetchHallShiftsScheduleQuery,
  useFetchHallSchemeQuery,
} = hallSchemaApi;

export function useSlots(interval?: TNullable<number>) {
  const place = useSelector(appContextSelectors.place);
  const date = useSelector(appContextSelectors.date);
  const isTabVisible = useIsTabVisible();
  const pollingInterval
    = isUndefined(interval) || isNull(interval)
      ? middlePollingInterval
      : interval;
  return hallSchemaApi.useFetchSlotsQuery(
    {
      placeId: place,
      date: date.format('YYYY-MM-DD'),
      time: moment().format('HH:mm'),
    },
    { pollingInterval, refetchOnMountOrArgChange: true, skip: !isTabVisible }
  );
}

export const useHallScheme = ({ placeId }: { placeId: number }) => {
  const { data, isFetching, refetch } = useFetchHallSchemeQuery({ placeId });

  if (!data) return undefined;

  return { ...data, id: placeId, figures: null, isFetching, refetch };
};

export function useCurrentHallSchedule() {
  const placeId = useSelector(appContextSelectors.place);
  const date = useSelector(appContextSelectors.date);
  const weekDay = useMemo(() => date.isoWeekday(), [date]);
  // request to build timeline
  return useFetchHallScheduleQuery({ placeId, weekDay });
}

export function useCurrentHallShiftsSchedule() {
  const place_id = useSelector(appContextSelectors.place);
  const date = useSelector(appContextSelectors.date);
  const restaurant = useSelector(appContextSelectors.restaurant);

  const params: HallSchemeShiftsPayload = {
    restaurant_id: restaurant.restaurant_id,
    date: date.format('YYYY-MM-DD'),
    place_id,
  };

  return useFetchHallShiftsScheduleQuery(params);
}

export function usePrepareShiftsSchedule() {
  const { data: intervals } = useCurrentHallShiftsSchedule();
  const { setTimelineConfig } = useTimelineActions();

  useEffect(() => {
    //Вычисляем время работы ресторана на основе шифтов
    if (!intervals) return;

    const firstValue = intervals[0]?.values;
    const lastValue = intervals[intervals.length - 1]?.values;

    if (!firstValue || !lastValue) return;

    const start = firstValue[0];
    const end = lastValue[lastValue.length - 1];
    const startShift = dayjs(start);
    const minutesShift = startShift.minute() / 60;
    const hoursShift = startShift.hour() + 1;
    const timeStart = hoursShift + minutesShift;
    const timelineLengthHours = dayjs(end).diff(startShift, 'h', true);
    setTimelineConfig({ timeStart, timelineLengthHours });
  }, [intervals]);
}

export /**
 * @deprecated инвалидация должна происходить на уровне api
 */
const invalidateHallSlots = () => hallSchemaApi.util.invalidateTags(SLOT_TAGS);
