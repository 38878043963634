import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import interactionPlugin, {
  EventResizeDoneArg,
} from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import FullCalendar from '@fullcalendar/react';
import {
  CalendarOptions,
  EventDropArg,
  SlotLabelContentArg,
} from '@fullcalendar/core';
import { v4 as uuid } from 'uuid';
import { Booked } from '../../ui-kit/ICONS/icons';
import { config } from '../../config';
import {
  appContextSelectors,
  useApplicationContextActions,
} from 'features/AppContex';
import { useSlots } from '../../features/api/hallschema-api';
import { useTagsOptions } from '../../features/api/tags';
import { TagOption } from '../../types/tag';
import './gridCssVariables.css';
import { TNullable, type ErrorResponse } from '../../types/commons';
import { useUpdateBookingHandler } from '../../features/api/bookings-api';
import { Booking } from '../../types/booking';
import { place, restaurant } from '../../features/AppContex/selectors';
import { TBookingUpdateParams } from '../../types/IBookingDTO';
import moment from 'moment';
import cn from 'classnames';
import { Card, Spinner } from 'ui-kit';
import { ETranslations } from '../../types/translates';
import { getBookingStartTime, isManagerialTable } from '../../utils';
import { useIntlUtils } from '../../hooks/useIntlUtils';
import { Modal } from '../../components/modal';
import { HideWhen } from '../../components/HideWhen';
import { EditBooking } from '../../components/hall-scheme/redux/TableBookingList/table-booking-list';
import { HallSchema } from '../../components/hall-scheme/redux/HallSchemaV2/hall-schema';
import { HallMode, useHallSchemaActions } from '../../features/HallSchema';
import { SelectGuestFromList } from '../../features/GuestsList/components/select-guest-from-list';
import { hallModeSelector } from '../../features/HallSchema/selectors';
import { Client } from '../../types/client';
import {
  fromProxySelectors,
  useFromProxyActions,
} from '../../features/BookingFormProxy';
import { useTimelineActions } from '../../features/Timeline';
import { BookingActions } from '../../components/BookingActions';
import { useHistory } from 'react-router';
import {
  GridHeader,
  renderResourceLabelContent,
} from './RequestGridComponents';
import styles from './requestsGrid.module.scss';
import { ConfirmOverbookingModal } from '../../components/modals/ConfirmOverbookingModal';
import { longPollingInterval } from '../../features/api/utils';

type TBookingThreshold = Record<string, Record<'bookings' | 'persons', number>>;
type TThresholdKeys = 'bookingsStartTime' | 'bookingsEndTime';
type TNewParams = Partial<
  Pick<Omit<TBookingUpdateParams, 'booking_time'>, 'visit_time' | 'tables'>
> &
  Pick<TBookingUpdateParams, 'booking_time'>;
type TOverBookingData = {
  booking: Booking;
  newParams: TNewParams;
  info: EventDropArg;
};

let bookingCountInTimeSlot = 0;
let personsCountInTimeSlot = 0;

const labelFormat: CalendarOptions['slotLabelFormat'] = {
  hour: '2-digit',
  minute: '2-digit',
  omitZeroMinute: false,
  hourCycle: 'h23',
};

export const RequestsGrid: FC = () => {
  const { intl, getIntlEntityEdition, isRussianLocale } = useIntlUtils();
  const mode = useSelector(hallModeSelector);
  const { switchMode } = useHallSchemaActions();
  const closeHall = useCallback(
    () => switchMode(HallMode.TABLES),
    [switchMode]
  );
  const closeGuests = useCallback(
    () => switchMode(HallMode.TABLE_BOOKINGS_EDIT),
    [switchMode]
  );
  const { setClient, setOnlyBooking, setBooking, setEditMode }
    = useFromProxyActions();
  const activeBooking = useSelector(fromProxySelectors.selectBooking);
  // for prevent polling when interact with bookings in timeline
  const [pollingInterval, setPollingInterval]
    = useState<TNullable<number>>(longPollingInterval);
  const schedulerRef = useRef<FullCalendar | null>(null);
  const [sortDirection, setSortDirection] = useState('');
  const date = useSelector(appContextSelectors.date);
  const bookingSlots = useSlots(pollingInterval);
  const { restaurant_id } = useSelector(restaurant);
  const tags = useTagsOptions({
    type: 'BOOKING',
    owner_type: restaurant_id + '',
    include_deleted: true,
  });
  const { updateBookingHandler } = useUpdateBookingHandler();
  const currentPlace = useSelector(place);
  const { setDate } = useApplicationContextActions();
  const { setTime, resetTimeShift } = useTimelineActions();
  const [isBookingDetailsOpen, setIsBookingDetailsOpen] = useState(false);
  const history = useHistory();
  const { setPlaceFromBooking } = useApplicationContextActions();
  const [overBookingData, setOverBookingData]
    = useState<TNullable<TOverBookingData>>(null);
  const [isEditable, setIsEditable] = useState(true);

  const startPolling = useCallback(() => setPollingInterval(null), []);
  const stopPolling = useCallback(() => setPollingInterval(0), []);
  const toggleSortDirection = useCallback(
    () => setSortDirection((prev) => (prev === '' ? '-' : '')),
    []
  );
  const turnOnEditable = useCallback(() => setIsEditable(true), []);
  const turnOffEditable = useCallback(() => setIsEditable(false), []);
  const clearData = useCallback(() => {
    overBookingData?.info.revert();
    setOverBookingData(null);
    turnOnEditable();
  }, [setOverBookingData, overBookingData, turnOnEditable]);

  const resources: CalendarOptions['resources'] = useMemo(() => {
    const result: CalendarOptions['resources'] = [];
    bookingSlots.currentData?.forEach(({ table }) => {
      const tableId = table.table_id.toString();

      result.push({
        id: tableId,
        title: table.number.toString(),
        extendedProps: {
          capacity: table.type,
        },
      });
    });
    return result;
  }, [bookingSlots, sortDirection]);

  const updateBookingFn = async ({
    booking,
    newParams,
    force = false,
  }: {
    booking: Booking;
    newParams: TNewParams;
    force?: boolean;
  }) => {
    const tables
      = newParams.tables
      || booking.places.map((placeItem) => ({
        place_id: placeItem.placeId,
        table_ids: [placeItem.id],
      }));
    const booking_time = newParams.booking_time;
    const visit_time = newParams.visit_time || booking.visitTime;

    const updateBody: TBookingUpdateParams = {
      bookingId: booking.bookingId,
      client: booking.client,
      contact: booking.contact,
      restaurant_id,
      booking_date: booking.bookingDate,
      tags: booking.tags.map(({ id, color, name }) => ({
        tag_id: id,
        color,
        description: name,
      })),
      persons: booking.persons,
      tables,
      booking_time,
      visit_time,
      force,
    };
    return updateBookingHandler(updateBody).unwrap();
  };

  const handleOnDateClick: CalendarOptions['dateClick'] = useCallback(
    (event) => {
      const dateTime = moment(event.date);
      const tableId = Number(event.resource.id);
      const table = bookingSlots.currentData?.find(
        (tableItem) => tableItem.table.table_id === tableId
      )?.table;
      if (table) {
        setBooking({
          booking: {
            bookingTime: dateTime.format('HH:mm'),
            places: [
              {
                id: table.table_id,
                number: table.number.toString(),
                place: { id: currentPlace },
              },
            ],
          },
          client: null,
        });
        history.push({
          pathname: '/create-booking',
          state: {
            from: history.location.pathname,
          },
        });
      }
    },
    [bookingSlots]
  );

  const handleOnBookingResize: CalendarOptions['eventResize'] = useCallback(
    async (info: EventResizeDoneArg) => {
      turnOffEditable();
      const booking = info.event.extendedProps.booking as Booking;
      const visitTime = moment
        .duration(moment(info.event.end).diff(info.event.start))
        .asMinutes();
      try {
        await updateBookingFn({
          booking,
          newParams: {
            booking_time: moment(info.event.start).format('HH:mm:ss'),
            visit_time: visitTime,
          },
        });
      } catch (e) {
        info.revert();
      } finally {
        turnOnEditable();
      }
    },
    [currentPlace]
  );

  const commonCapacity = useMemo(
    () =>
      resources.reduce(
        (acc, resource) => acc + resource.extendedProps?.capacity,
        0
      ),
    [resources]
  );

  const calendarData: { firstEventTime: TNullable<Dayjs> } & Pick<
    CalendarOptions,
    'resources' | 'events'
  > &
    Record<TThresholdKeys, TBookingThreshold> = useMemo(() => {
    const events: CalendarOptions['events'] = [];
    const bookingsStartTime: TBookingThreshold = {};
    const bookingsEndTime: TBookingThreshold = {};
    let firstEventTime: TNullable<Dayjs> = null;

    bookingSlots.currentData?.forEach(({ table, slots }) => {
      const tableId = table.table_id.toString();

      slots.forEach((slot) => {
        const startDateTime = dayjs(
          `${slot.booking.bookingDate} ${slot.bookingStartTime}`
        );
        firstEventTime = startDateTime;
        const startTime = startDateTime.format('HH:mm');
        const endTime = startDateTime
          .add(slot.booking.visitTime, 'minute')
          .format('HH:mm');

        const persons = slot.booking.persons;
        if (!bookingsStartTime[startTime]) {
          bookingsStartTime[startTime] = { bookings: 1, persons: persons };
        } else if (bookingsStartTime[startTime]) {
          bookingsStartTime[startTime].bookings
            = bookingsStartTime[startTime].bookings + 1;
          bookingsStartTime[startTime].persons
            = bookingsStartTime[startTime].persons + persons;
        }
        if (!bookingsEndTime[endTime]) {
          bookingsEndTime[endTime] = { bookings: 1, persons: persons };
        } else if (bookingsEndTime[endTime]) {
          bookingsEndTime[endTime].bookings
            = bookingsEndTime[endTime].bookings + 1;
          bookingsEndTime[endTime].persons
            = bookingsEndTime[endTime].persons + persons;
        }

        events.push({
          id: uuid(),
          resourceId: tableId,
          title: [
            slot.booking?.client?.name ?? slot.booking?.contact?.name,
            slot.booking?.client?.middle_name
              ?? slot.booking?.contact?.middle_name,
            slot.booking?.client?.surname ?? slot.booking?.contact?.surname,
          ].join(' '),
          start: startDateTime.format('YYYY-MM-DD HH:mm'),
          end: startDateTime
            .add(slot.booking.visitTime, 'minute')
            .format('YYYY-MM-DD HH:mm'),
          extendedProps: {
            booking: slot.booking,
            tags: tags.filter((tagItem) =>
              slot.booking?.client?.tags?.includes(tagItem.value)),
          },
        });
      });
    });

    return {
      events,
      bookingsStartTime,
      bookingsEndTime,
      firstEventTime,
    };
  }, [bookingSlots, sortDirection, resources]);

  const updateWithOverbooking = useCallback(async () => {
    if (!overBookingData) return;
    const { info, newParams, booking } = overBookingData;
    try {
      const updatedBooking = await updateBookingFn({
        booking,
        newParams,
        force: true,
      });
      info.event.setExtendedProp('booking', updatedBooking.data);
    } catch (e) {
      info.revert();
    } finally {
      setOverBookingData(null);
      startPolling();
      turnOnEditable();
    }
  }, [overBookingData]);

  const handleOnBookingDrop: CalendarOptions['eventDrop'] = useCallback(
    async (info: EventDropArg) => {
      turnOffEditable();
      const booking = info.event.extendedProps.booking as Booking;
      const prevTableId = info.oldResource?.id;
      const nextTableId = info.newResource?.id;

      const table_ids: number[] = [];
      booking.places.map((placeItem) => {
        if (!prevTableId || placeItem.id !== Number(prevTableId)) {
          table_ids.push(placeItem.id);
        }
      });
      if (nextTableId) {
        table_ids.push(Number(nextTableId));
      }

      const newParams = {
        booking_time: moment(info.event.start).format('HH:mm:ss'),
        tables: [{ place_id: currentPlace, table_ids }],
      };
      try {
        const updatedBooking = await updateBookingFn({
          booking,
          newParams,
        });
        startPolling();
        turnOnEditable();
        info.event.setExtendedProp('booking', {
          ...updatedBooking.data,
          isOverbooking: false,
        });
      } catch (e) {
        if ((e as ErrorResponse['error'])?.data?.errorCode === 10100) {
          setOverBookingData({ booking, info, newParams });
          return;
        }
        info.revert();
        startPolling();
        turnOnEditable();
      }
    },
    [currentPlace, calendarData.events]
  );

  const isCalendarResourcesReady = useMemo(() => {
    return resources instanceof Array && resources.length > 0;
  }, [resources, bookingSlots]);

  const isNoTables = useMemo(() => {
    return (
      bookingSlots.status !== 'pending'
      && resources instanceof Array
      && resources.length === 0
    );
  }, [resources]);

  const clearBooking = useCallback(() => {
    setIsBookingDetailsOpen(false);
    setOnlyBooking(undefined);
    resetTimeShift();
    closeHall();
  }, []);

  const handleOnEventClick = useCallback((book: Booking) => {
    setPlaceFromBooking(book);
    setDate(moment(book.bookingDate));
    setTime(getBookingStartTime(book));
    setOnlyBooking(book);
    setIsBookingDetailsOpen(true);
  }, []);

  const handleSetClient = useCallback(
    (client: Client) => {
      setClient({ client });
      closeGuests();
    },
    [setClient, closeGuests]
  );

  const handleOnEdit = useCallback((booking: Booking) => {
    setPlaceFromBooking(booking);
    setOnlyBooking(booking);
    setTimeout(setEditMode, 0, true);
  }, []);

  const renderEventContent: CalendarOptions['eventContent'] = useCallback(
    (eventInfo) => {
      const clientTags = eventInfo.event.extendedProps.tags as TagOption[];
      const booking = eventInfo.event.extendedProps.booking as Booking;

      const withTags = clientTags.length > 0;

      return (
        <div
          className={cn(styles.bookingItemInner, {
            [styles.isOverbooking]: booking.isOverbooking,
          })}
          onClick={() => handleOnEventClick(booking)}
        >
          <div className={styles.bookingStatuses}>
            {booking.extraStatus && (
              <div style={{ background: booking.extraStatus.color || '' }} />
            )}
            <div
              style={{
                background:
                  booking.seatType === 'MANAGEMENT'
                    ? 'var(--status_constant_main_background)'
                    : booking.status.color || '',
              }}
            />
          </div>
          <span className={styles.persons}>{booking.persons}</span>
          <span className={styles.clientName} title={eventInfo.event.title}>
            {eventInfo.event.title}
          </span>
          {withTags && (
            <div className={styles.clientTags}>
              {clientTags.slice(0, 3).map((tag) => {
                return (
                  <span
                    key={tag.label}
                    style={{ background: tag.color }}
                    className={styles.clientTag}
                    title={clientTags
                      .map((tagItem) => tagItem.label)
                      .join(', ')}
                  />
                );
              })}
            </div>
          )}
          {!isManagerialTable(booking) && (
            <BookingActions
              placement="top-start"
              booking={booking}
              className={styles.contextMenuIcon}
              onEdit={() => handleOnEdit(booking)}
            />
          )}
        </div>
      );
    },
    []
  );

  const renderSlotLabelContent: CalendarOptions['slotLabelContent']
    = useCallback(
      (labelInfo: SlotLabelContentArg) => {
        if (calendarData.bookingsStartTime[labelInfo.text]) {
          bookingCountInTimeSlot
            += calendarData.bookingsStartTime[labelInfo.text].bookings;
          personsCountInTimeSlot
            += calendarData.bookingsStartTime[labelInfo.text].persons;
        }
        if (calendarData.bookingsEndTime[labelInfo.text]) {
          bookingCountInTimeSlot
            -= calendarData.bookingsEndTime[labelInfo.text].bookings;
          personsCountInTimeSlot
            -= calendarData.bookingsEndTime[labelInfo.text].persons;
        }

        return (
          <div className={styles.slotLabel}>
            <span className={styles.slotTime}>{labelInfo.text}</span>
            <div className={styles.booked}>
              <Booked color="var(--gl_icon_primary_1)" />
              <span className={styles.bookedCount}>
                {bookingCountInTimeSlot}
              </span>
            </div>
            <span className={styles.capacity}>
              {personsCountInTimeSlot || 0}/{commonCapacity}
            </span>
          </div>
        );
      },
      [calendarData]
    );

  const scrollTime: CalendarOptions['scrollTime'] = useMemo(() => {
    const now = dayjs();
    const time = now.isSame(date.toDate(), 'd')
      ? now
      : calendarData.firstEventTime;
    return time?.format('HH:mm');
  }, [date, calendarData.firstEventTime]);

  useEffect(() => {
    const schedulerApi = schedulerRef.current?.getApi();
    if (!schedulerApi) return;
    schedulerApi.gotoDate(date.toDate());
  }, [date, schedulerRef]);

  useEffect(() => {
    if (isCalendarResourcesReady) {
      const areaHeader = document.querySelector('.areaHeaderWrapper');
      areaHeader?.addEventListener('click', toggleSortDirection);
      return () => {
        areaHeader?.removeEventListener('click', toggleSortDirection);
      };
    }
  }, [isCalendarResourcesReady]);

  useEffect(() => {
    setPollingInterval(isBookingDetailsOpen ? 0 : null);
  }, [isBookingDetailsOpen]);

  return (
    <div className={styles.grid}>
      {!isCalendarResourcesReady && !isNoTables && (
        <Spinner className={styles.calendarLoader} />
      )}
      {isNoTables && (
        <div className={styles.noTablesOrShifts}>
          {intl.formatMessage({ id: ETranslations.REQUEST_GRID_NO_TABLES })}
        </div>
      )}
      {isCalendarResourcesReady && !isNoTables && (
        <FullCalendar
          ref={schedulerRef}
          plugins={[interactionPlugin, resourceTimelinePlugin]}
          headerToolbar={{
            left: '',
            center: '',
            right: '',
          }}
          initialView="resourceTimeline"
          resourceOrder={`${sortDirection}title`}
          initialDate={date.toDate()}
          resources={resources}
          events={calendarData.events}
          eventTimeFormat={labelFormat}
          slotLabelFormat={labelFormat}
          handleWindowResize
          eventContent={renderEventContent}
          slotLabelContent={renderSlotLabelContent}
          resourceLabelContent={renderResourceLabelContent}
          schedulerLicenseKey={config.fullCalendarKey}
          editable={isEditable}
          height={'100%'}
          nowIndicator
          slotDuration={{ minutes: 15 }}
          slotLabelInterval={{ minutes: 15 }}
          slotMinWidth={55}
          resourceAreaHeaderContent={
            <GridHeader sortDirection={sortDirection} />
          }
          resourceAreaHeaderClassNames="areaHeaderWrapper" // for exact dom query
          resourceAreaWidth={120}
          eventClassNames={styles.bookingItem}
          eventResizeStart={stopPolling}
          eventResizeStop={startPolling}
          eventResize={handleOnBookingResize}
          eventDragStart={stopPolling}
          eventDrop={handleOnBookingDrop}
          scrollTime={scrollTime}
          scrollTimeReset={false}
          dateClick={handleOnDateClick}
        />
      )}
      {isBookingDetailsOpen && activeBooking?.bookingId && (
        <Modal
          isOpen={Boolean(activeBooking)}
          onClose={clearBooking}
          title={getIntlEntityEdition(
            isRussianLocale
              ? ETranslations.PLURAL_BOOKINGS_NOM
              : ETranslations.PLURAL_BOOKING
          )}
        >
          <Modal.Content noPadding className={styles.modalGrid}>
            <HideWhen
              condition={
                [
                  HallMode.BOOKING_GUEST,
                  HallMode.TABLE_BOOKINGS_EDIT_GUEST,
                ].includes(mode) || mode.includes('HALL')
              }
              noUnmount
            >
              <div>
                {activeBooking?.bookingId && (
                  <EditBooking bookingId={activeBooking.bookingId} hideCard />
                )}
              </div>
            </HideWhen>
            <HideWhen condition={!mode.includes('HALL')}>
              <Card onClose={closeHall}>
                <Card.Header
                  title={intl.formatMessage({ id: ETranslations.HALL_SCHEME })}
                />
                <Card.Content className={styles.scheme}>
                  <HallSchema />
                </Card.Content>
              </Card>
            </HideWhen>
            <HideWhen
              condition={
                ![
                  HallMode.BOOKING_GUEST,
                  HallMode.TABLE_BOOKINGS_EDIT_GUEST,
                ].includes(mode)
              }
            >
              <div className={cn(styles.guests)}>
                <SelectGuestFromList
                  onClose={closeGuests}
                  onSelectGuest={handleSetClient}
                />
              </div>
            </HideWhen>
          </Modal.Content>
        </Modal>
      )}
      <ConfirmOverbookingModal
        isOpen={!!overBookingData}
        onDecline={clearData}
        onConfirm={updateWithOverbooking}
      />
    </div>
  );
};
